/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import profileStore, { UserSelector } from './modules/authentication/store';
import { MainRouter } from './routers';


function App() {
  const user = useSelector(UserSelector);
  const dispatch = useDispatch();


  React.useEffect(() => {
    try {
      const bc = new BroadcastChannel("remy_share_channel");
      bc.onmessage = (event) => {
        if (event.origin === window.location.origin) {
          if (event.data !== "loaded" && event.data !== "" && user == null)
            dispatch(profileStore.actions.fetchProfile({ user: JSON.parse(event.data) }))
          else if (event.data === "loaded" && user != null) {
            bc.postMessage(JSON.stringify(user));
          }
        }
      }
      if (user != null) {
        bc.postMessage(JSON.stringify(user));
      } else {
        bc.postMessage("loaded")
      }
      return () => {
        bc.close();
      }
    } catch (ex) {

    }
  }, [user])

  return <MainRouter />;
}

export default App;
