export const menus = [
  { path: "/website/louis-xiii/index.html", name: "Louis XIII" },
  { path: "/website/remy-martin/index.html", name: "Rémy Martin" },
  { path: "/website/bruichladdich/index.html", name: "Bruichladdich" },
  { path: "/website/botanist/index.html", name: "The Botanist" },
  { path: "/website/mount-gay/index.html", name: "Mount Gay" },
  { path: "/website/metaxa/index.html", name: "Metaxa" },
  { path: "/website/st-remy/index.html", name: "St-Rémy" },
  { path: "/website/cointreau/index.html", name: "Cointreau" },
  { path: "/website/telmont/index.html", name: "Telmont" },
];
