import React, { useState } from "react";
import { PropsWithChildren } from "react";
import { Interactive } from "src/hooks/interactive";
import Header from "../../views/Components/Header";
import "./index.css"

const Layout: React.FC<PropsWithChildren> = (props) => {
    const [interactive, setInteractive] = useState(false);
    const onClick = React.useCallback(() => {
        if(!interactive){
            setInteractive(true);
        }
    },[interactive]);
    return <Interactive.Provider value={{ interactive }}>
        <div className="main-layout" onClick={onClick}>
            <Header></Header>
            <div className="page">{props.children}</div>
        </div>
    </Interactive.Provider>
}
export default React.memo(Layout);