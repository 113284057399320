import React from "react";
import { useSelector } from "react-redux";
import { menus } from "src/configs";
import { TokenSelector } from "src/store";
import { logo, mail } from "../../../assets"
import "./index.css";

const Header = () => {
    const token = useSelector(TokenSelector)
    const uiMenus = React.useMemo(() => {
        return menus;
    }, [])
    return <header className="header">
        <div className="main-content">
            <img src={logo} alt="logo" className="logo"></img>
            <div className="header-menu">{
                token && uiMenus.map((m, index) => {
                    return <a href={m.path} key={index} className="menu-link">{m.name}</a>
                })
            }</div>
            {token && <a href="mailto:TFWACannes2022@remy-cointreau.com" target="_blank" className="mail" rel="noreferrer"><img src={mail} alt="mail" className="w-100"></img></a>}
        </div>
    </header>
}
export default React.memo(Header);