import { combineReducers } from '@reduxjs/toolkit';
import profileStore from './authentication/store';
import * as authentication from './authentication/repository'

const appReducer = combineReducers({
  profile: profileStore.reducer,
});

export type RootState = ReturnType<typeof appReducer>;
export default appReducer;

export const repositories={
  authentication
}
