import axios from "axios";

const http = axios.create({ baseURL: process.env.REACT_APP_API_URL, headers: { 'Content-Type': 'application/json;charset=utf-8' } })


export const sendOtp = (email: string) => {
    return http.post("./api/send-otp", { email }).catch(err=>{
        if(err.response?.data?.message==="SendOTPInvalid"){
            return Promise.resolve({email})
        }
        return Promise.reject(err);
    });
}

export const verifyOtp =(otp:number)=>{
    return http.post("./api/verify-otp", { otp });
}