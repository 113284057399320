import { logger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import storage from './storage';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import appReducer, { RootState } from '../modules/index';
import { Selector } from 'react-redux';

const profile = createWhitelistFilter('profile', ['token']);

const persistConfig:PersistConfig<RootState> = {
  key: 'remy-2022',
  storage: storage,
  blacklist: [],
  transforms: [profile],
  whitelist: ['profile'],
};

const persistedReducer = persistReducer(persistConfig, appReducer);
const middleware:any = [];
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}
const store = createStore(persistedReducer, applyMiddleware(...middleware));
export const persistor = persistStore(store);


export default store;


export const TokenSelector:Selector<RootState, string> = (state)=>{
  return state.profile.token||"";
};
