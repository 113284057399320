/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { TokenSelector } from 'src/store';
import { privatePage, publicPage } from 'src/views';
import Layout from './Layout';

export const MainRouter = () => {
    const token = useSelector(TokenSelector)
    const MainPages: Array<React.ReactElement|null> = React.useMemo(() => {
        if (token === "") {
            return publicPage.map(p => {
                const Page = p.page;
                return <Route key={p.path} path={p.path} element={<React.Suspense><Page /></React.Suspense>} />
            })
        }
        return privatePage.map(p => {
            const Page = p.page;
            return <Route key={p.path} path={p.path} element={<React.Suspense><Page /></React.Suspense>} />
        });
    }, [token])


    return <Layout>
            <Routes>
            {
                MainPages
            }
        </Routes>
    </Layout>
}