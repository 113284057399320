import {
    createAction,
    createSlice,
    PayloadAction,
    Selector,
} from '@reduxjs/toolkit';
import { RootState } from '../index';
import UserEntity from './entity';


export const removeProfile = createAction('authentication/removeProfile');
export const setToken = createAction<{ token: any; remember: boolean }>(
    'authentication/setToken',
);
const persist = createAction<{ profile: { token: string } }>("persist/REHYDRATE")
interface IStore {
    user?: UserEntity;
    listPermissionCode?: Array<string>;
    linkImage?: string;
    token?: string;
    remember: boolean;
}

const profileStore = createSlice({
    name: 'profileStore',
    initialState: {
        listPermissionCode: [],
        remember: false,
    } as unknown as IStore,
    reducers: {
        fetchProfile: (
            state,
            action: PayloadAction<{ user?: UserEntity; listPermissionCode?: string[] }>,
        ) =>
            Object.assign(state, {
                statusLogin: action.payload.user != null,
                user: action.payload.user,
                listPermissionCode: action.payload.listPermissionCode || [],
                token: action.payload.user != null ? JSON.stringify(action.payload.user) : undefined
            }),
        updateProfile: (
            state,
            action: PayloadAction<{
                listPermissionCode?: string[];
                statusLogin?: boolean;
            }>,
        ) => Object.assign(state, action.payload)
    },
    extraReducers: (builder) => {
        builder
            .addCase(removeProfile, (state: any) => {
                return {
                    ...state,
                    user: undefined,
                    listPermissionCode: [],
                    token: undefined,
                };
            })
            .addCase(setToken, (state, action) =>
                Object.assign(state, action.payload),
            )
            .addCase(persist, (state, action) => {
                let user = undefined;
                if (action.payload?.profile?.token) {
                    user = new UserEntity(JSON.parse(action.payload.profile.token))
                }
                return {
                    ...state,
                    token: action.payload?.profile?.token,
                    user
                }
            })
    },
});

interface IToken {
    token: string;
}

export const TokenSelector: Selector<RootState, IToken> = (state) => {
    return {
        token: state.profile.token || '',
    };
};


export const UserSelector: Selector<RootState, UserEntity|undefined> = (state) => {
    return state.profile.user;
};

export default profileStore;
