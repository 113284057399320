class UserEntity {
  name = '';

  email = '';


  constructor(user: Partial<UserEntity>) {
    if (!user) return;
    Object.assign(this, user);
   
  }

  static createArrayUser(arrUser: Array<any>): Array<UserEntity> {
    const list = arrUser.map((x) => new UserEntity(x));
    return list;
  }
}

export default UserEntity;
